<template>
  <div class="landing-page">
    <div class="landing-container" v-if="merchant && merchant.options.websiteActivated">

      <div class="landing__background" :style="{'backgroundImage': 'url('+merchant.backgroundImages[0]+')'}"></div>
      <div class="landing__header">
        <div class="header__actions">
          <button @click="showMenu = true">
            <i class="material-icons notranslate">menu</i>
          </button>

          <div class="landing__floating-menu" :class="{'landing__floating-menu-active':showMenu}">
            <div class="floating-menu__header">
              <strong>{{merchant.name}}</strong>
              <button @click="showMenu = false">
                <i class="material-icons notranslate">close</i>
              </button>
            </div>
            <div class="floating-menu__content">
              <div class="item" @click="scrollToElement('discover')">Découvrir</div>
              <a :href="BookingLink+'/'+merchant.slug" v-if="merchant.options.bookingEnabled" @click="showMenu = false" class="item">Réserver une table</a>
           <div class="item" @click="openMerchant('on_site')" v-if="merchant.options.onSiteEnabled">Consulter le menu sur place</div>
              <div class="item" @click="openMerchant('delivery')" v-if="merchant.options.deliveryEnabled">Commander en livraison</div>
              <div class="item" @click="openMerchant('pickup')" v-if="merchant.options.pickupEnabled">Commander en Click & Collect</div>
              <div class="item" @click="openMerchant('on_site_counter')" v-if="merchant.options.onsiteCounterEnabled">Commander au comptoir</div>
              <div @click="scrollToElement('social_networks')" v-if="merchant.social.length > 0 || merchant.platforms.length > 0" class="item">Réseaux sociaux</div>
              <div @click="scrollToElement('infos')" class="item">Infos pratiques</div>
            </div>
          </div>

          <button v-if="shareBtn" @click="share">
            <i class="material-icons notranslate">share</i>
          </button>
        </div>

        <div class="header__hero">
          <div class="hero__logo"><img :src="merchant.logo.path" alt=""></div>
          <div class="hero__infos">
            <h1>{{merchant.name}}</h1>
            <p>{{merchant.address.address}}, {{merchant.address.zip}} {{merchant.address.city}}</p>
            <p>{{merchant.categories}}</p>
          </div>

          <div class="hero__available" @click="scrollToElement('infos')" >
            <merchant-availability></merchant-availability>
          </div>

          <div class="hero__buttons">
            <a :href="'tel:'+merchant.phone" v-if="merchant.showPhone">{{merchant.parsedPhone}}</a>
            <a :href="BookingLink+'/'+merchant.slug"  v-if="merchant.options.bookingEnabled">Réserver une table</a>
          </div>


        </div>
      </div>


      <div class="landing__order-actions">
        <div class="mb-3 w-100" style="max-width: 340px;" v-if="merchant.announcements.some(an => an.position === 'website_front')">
          <announcement position="website_front"></announcement>
        </div>

         <button class="pickup" @click="openMerchant('on_site_counter')" v-if="merchant.options.onsiteCounterEnabled">
          <i class="material-icons notranslate">dinner_dining</i>
          Commander au comptoir
        </button>

        <button class="pickup" @click="openMerchant('on_site')" v-if="merchant.options.onSiteEnabled">
          <i class="material-icons notranslate">restaurant</i>
          Consulter le menu sur place
        </button>
        <button class="delivery" @click="openMerchant('delivery')" v-if="merchant.options.deliveryEnabled">
          <i class="material-icons notranslate">directions_bike</i>
          Commander en livraison
        </button>
        <button class="pickup" @click="openMerchant('pickup')" v-if="merchant.options.pickupEnabled">
          <i class="material-icons notranslate">local_mall</i>
          Commander en Click&Collect
        </button>


      </div>


      <div class="landing__merchant-info" id="discover" v-if="merchant.subtitle || merchant.description || highlights.length > 0">
        <div class="infos">
          <h1>{{merchant.name}}</h1>
          <h2>{{merchant.subtitle}}</h2>
          <p>{{!showBigDescription ? merchant.descriptionTrimmed : merchant.description}}<a @click.prevent="showBigDescription = true" href="#" v-if="merchant.bigDescription && !showBigDescription">Voir plus</a><a @click.prevent="showBigDescription = false" href="#" v-if="merchant.bigDescription && showBigDescription">Voir moins</a></p>
        </div>

        <div class="gallery-3" :class="{'animated': animateGallery}" v-if="highlights.length >= 6">
          <div class="column">
            <div class="image image-large" @click="openLightBox(0)" :style="{'backgroundImage': 'url('+highlights[0].imageLarge.path+')'}"></div>
            <div class="image image-small" @click="openLightBox(1)" :style="{'backgroundImage': 'url('+highlights[1].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-small" @click="openLightBox(2)" :style="{'backgroundImage': 'url('+highlights[2].imageLarge.path+')'}"></div>
            <div class="image image-large" @click="openLightBox(3)" :style="{'backgroundImage': 'url('+highlights[3].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-large" @click="openLightBox(4)" :style="{'backgroundImage': 'url('+highlights[4].imageLarge.path+')'}"></div>
            <div class="image image-small" @click="openLightBox(5)" :style="{'backgroundImage': 'url('+highlights[5].imageLarge.path+')'}"></div>
          </div>
        </div>
        <div class="gallery-3" :class="{'animated': animateGallery}" v-if="highlights.length === 5">
          <div class="column">
            <div class="image image-large" @click="openLightBox(0)" :style="{'backgroundImage': 'url('+highlights[0].imageLarge.path+')'}"></div>
            <div class="image image-small" @click="openLightBox(1)" :style="{'backgroundImage': 'url('+highlights[1].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-small" @click="openLightBox(2)" :style="{'backgroundImage': 'url('+highlights[2].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-large" @click="openLightBox(3)" :style="{'backgroundImage': 'url('+highlights[3].imageLarge.path+')'}"></div>
            <div class="image image-small" @click="openLightBox(4)" :style="{'backgroundImage': 'url('+highlights[4].imageLarge.path+')'}"></div>
          </div>
        </div>
        <div class="gallery-3" :class="{'animated': animateGallery}" v-if="highlights.length === 4">
          <div class="column">
            <div class="image image-small" @click="openLightBox(0)" :style="{'backgroundImage': 'url('+highlights[0].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-large" @click="openLightBox(1)" :style="{'backgroundImage': 'url('+highlights[1].imageLarge.path+')'}"></div>
            <div class="image image-small" @click="openLightBox(2)" :style="{'backgroundImage': 'url('+highlights[2].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-small" @click="openLightBox(3)" :style="{'backgroundImage': 'url('+highlights[3].imageLarge.path+')'}"></div>
          </div>
        </div>
        <div class="gallery-3" :class="{'animated': animateGallery}" v-if="highlights.length === 3">
          <div class="column">
            <div class="image image-small" @click="openLightBox(0)" :style="{'backgroundImage': 'url('+highlights[0].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-small" @click="openLightBox(1)" :style="{'backgroundImage': 'url('+highlights[1].imageLarge.path+')'}"></div>
          </div>
          <div class="column">
            <div class="image image-small" @click="openLightBox(2)" :style="{'backgroundImage': 'url('+highlights[2].imageLarge.path+')'}"></div>
          </div>
        </div>
      </div>



      <div class="landing__links" id="social_networks" v-if="merchant.social.length > 0 || merchant.platforms.length > 0">

        <div class="social-networks" v-if="merchant.social.length > 0">
          <a :href="s.url" target="_blank" class="link" v-for="(s,i) in merchant.social" :key="i">
            <div class="link__image">
              <img class="logo" v-if="s.socialNetwork === 'facebook'" src="/img/social/fb.svg" alt="">
              <img class="logo" v-else-if="s.socialNetwork === 'twitter'" src="/img/social/twitter.svg" alt="">
              <img class="logo" v-else-if="s.socialNetwork === 'messenger'" src="/img/social/messenger.svg" alt="">
              <img class="logo" v-else-if="s.socialNetwork === 'instagram'" src="/img/social/instagram.svg" alt="">
              <img class="logo" v-else :src="s.logo.path" alt="">
            </div>
            <span>{{s.socialNetwork}}</span>
          </a>
        </div>
        <div class="social-networks" v-if="merchant.platforms.length > 0">
          <a :href="merchant.platforms.find(p => p.socialNetwork === 'ubereat').url" target="_blank" class="link" v-if="merchant.platforms.find(p => p.socialNetwork === 'ubereat')">
            <div class="link__image" style="background-color: #37464e;">
              <img class="platform-logo" src="/img/ubereat.svg">
            </div>
            <span>Uber eats</span>
          </a>
          <a href="/order?type=delivery" class="link">
            <div class="link__image" style="background-color: #37beee;">
              <img class="platform-logo" src="/img/delicity_scare.svg">
            </div>
            <span>Delicity</span>
          </a>
          <a :href="merchant.platforms.find(p => p.socialNetwork === 'deliveroo').url" target="_blank" class="link" v-if="merchant.platforms.find(p => p.socialNetwork === 'deliveroo')">
            <div class="link__image" style="background-color: #00cabb;" >
              <img class="platform-logo" src="/img/deliveroo.svg">
            </div>
            <span>Deliveroo</span>
          </a>
        </div>
      </div>

      <div class="landing__map" @click="openMap" :style="{'backgroundImage': 'url('+merchant.map.path+')'}"></div>

      <div class="landing__footer" id="infos">
        <div class="footer__info">
          <div class="footer__address">
            <strong>{{merchant.name}}</strong>
            <span>{{merchant.address.address}}</span>
            <span>{{merchant.address.address2}}</span>
            <span>{{merchant.address.zip}} {{merchant.address.city}}</span>
            <a :href="'tel:'+merchant.phone" v-if="merchant.showPhone">{{merchant.parsedPhone}}</a>
          </div>
          <img :src="merchant.logo.path">
        </div>
        <div class="footer__schedules">
          <table class="week-hours">
            <tr v-for="shift in shifts" :class="{'current-day': currentDayIso == (shift[0].weekDay+1)}">
              <td class="pt-2" style="width: 130px;"><span>{{shift[0].weekDayFormated}}</span></td>
              <td class="hours pt-2">
                <span v-if="s.start && s.end" v-for="s in shift" class="mr-4">{{s.start}} - {{s.end}}</span>
                <span v-if="!s.start || !s.end" v-for="s in shift" class="closed">Fermé</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="footer__love">
          <span>Fait avec<img src="/img/heart.png">à Nice</span>
          <a href="https://delicity.com" target="_blank" class="platform">
            <img src="/img/delicity_footer.svg">
          </a>
        </div>
        <div class="footer__copyright">
          <span>© 2022 {{merchant.host}} · <a href="https://pro.delicity.com/cgv/" target="_blank">CGV</a> · <a href="https://pro.delicity.com/legal/" target="_blank">Mentions légales</a></span>
        </div>
      </div>

    </div>
    <div v-else class="loading-screen">
      <loader></loader>
    </div>


    <vue-easy-lightbox
        :moveDisabled="true"
        :visible="isLightBoxVisible"
        :imgs="lightBoxImages"
        :index="lightBoxIndex"
        @hide="isLightBoxVisible = false">
      <template v-slot:toolbar="{ toolbarMethods }"><div></div></template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import '@/scss/app_desktop.scss';
import Loader from "@/components/Loader";
import VueEasyLightbox from 'vue-easy-lightbox'
import MerchantAvailability from "@/components/MerchantAvailability";
import moment from "moment";
import config from "@/config";
import Announcement from "@/mobileComponents/Merchant/Announcement";

export default {
  components: {Loader, VueEasyLightbox, MerchantAvailability, Announcement},
  data() {
    return {
      shareBtn: false,
      animateGallery: false,
      showMenu: false,
      isLightBoxVisible: false,
      lightBoxImages: [],
      lightBoxIndex: 0,
      showBigDescription: false,
      currentDayIso: moment().isoWeekday(),
      BookingLink: config.bookingLink
    }
  },
  methods: {
    ...mapActions(['getMerchant']),
    openMerchant(type) {
      this.$router.push('/order?type='+type);
    },
    updateFavicon(){
      if(this.merchant && this.merchant.metadata){
        document.getElementById('ico').href = this.merchant.metadata.icon;
        document.getElementById('i180').href = this.merchant.metadata.icon180x180;
        document.getElementById('i32').href = this.merchant.metadata.icon32x32;
        document.getElementById('i16').href = this.merchant.metadata.icon16x16;
      }
    },
    openSocial(url){
      window.open(url, '_blank');
    },
    callMerchant(phone){
      window.open('tel:'+phone);
    },
    scrollToElement(id){
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
      this.showMenu = false;
    },
    openMap(){
      window.open('https://www.google.com/maps?q='+this.merchant.name+' '+this.merchant.address.address+', '+this.merchant.address.city, '_blank');
    },
    share(){
      if (navigator.share) {
        navigator.share({
          text: this.merchant.name + ' à ' + this.merchant.address.city,
          url: location.href
        }).then(() => {
        }).catch(console.error);
      }
    },
    scrollListen(){
      const body = document.body;
      const windowHeight = window.innerHeight;
      if(windowHeight > 950){
          this.animateGallery = true;
      }
      body.onscroll = (e) => {
        if (window.scrollY > 100) {
          this.animateGallery = true;
        }
      }
    },
    openLightBox(index){
      this.lightBoxImages = this.highlights.map(h => h.imageLarge.path);
      this.lightBoxIndex = index;
      this.isLightBoxVisible = true;
    }
  },
  computed: {
    ...mapGetters(['merchant', 'shifts', 'products']),
    highlights() {
      return this.products.filter(p => p.highlighted===true && p.imageLarge.path);
    }
  },
  async mounted() {
    if (navigator.share) {
      this.shareBtn = true;
    }
    const slug = this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host;
    await this.getMerchant({slug: slug, type: this.$route.query.type});
    this.updateFavicon();
    if(this.merchant.options.websiteActivated === false){
      location.href = '/order';
    }

    let root = document.documentElement;
    root.style.setProperty('--theme-primary', this.merchant.theme.primary);
    root.style.setProperty('--theme-secondary', this.merchant.theme.secondary);

    this.scrollListen();
  }
}
</script>
